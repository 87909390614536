import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

    siteToken: string;
    siteKey: string;
    remoteip: string;
    istrue: boolean;
    isformerror: boolean;
    dispemailerr: boolean;
    apiResponse: any;
    name: string;
    email: string;
    country: string = "Thailand";
    domain: string = "www.autotrade-software.com";
    phone: number;
    city: string;
    bodydf: any;
    viewbutton: boolean;
    erroresponse: any;
    phoneNo: number;
    phonerr: boolean;
    phonesucc: boolean;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.addRecaptchaScript();
    this.getIPAddress();
    this.istrue = true;
    this.isformerror = true;
    this.viewbutton = false;
    this.dispemailerr = true;
    this.phonerr=true;
    this.phonesucc=true;
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LcahYEaAAAAAPjw3uS0pV0ypK7o0WSdxVQ-kTDw',
      'callback': (response) => {
          this.siteToken = response;
      }
    });
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  reloadcaptcha() {
  window['grecaptcha'].reset(this.recaptchaElement.nativeElement);
  }

  getIPAddress()  {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.remoteip = res.ip;
    });
  }

  clearErrors(event): any {
    this.isformerror = true;
    this.dispemailerr = true;
  }

  numberOnly(event): boolean {
    this.isformerror = true;
    this.dispemailerr = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 43) {
      return true;
    } else if((charCode > 32 && (charCode < 48 || charCode > 57 ))){
      return false;
    } else {
      return true;
    }

  }

  alphabetsOnly(event): boolean {
    this.isformerror = true;
    this.dispemailerr = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32){
      return true;
    }
     return false;
  }

  onSubmit(form: NgForm) {
if(form.valid) {
  this.viewbutton = true;
  this.isformerror = true;
  const headers = new HttpHeaders()
 .set('Access-Control-Allow-Origin','*');
 this.http.post('https://staffdesk.infinitysoftware.asia/api/angular/sites/enquiry-dop',{name: form.value.name, phone: form.value.phone, email: form.value.email, city: form.value.city,siteToken: form.value.siteToken, remoteip: form.value.remoteip, domain: form.value.domain,country: form.value.country},{'headers' : headers}).subscribe(response => {
 console.warn('got the post response',response);
 if(response == 'Success'){
   this.istrue = false;
   this.dispemailerr = true;
   this.isformerror = true;
 }
 else if (response == 'validate'){
   window.location.reload();
 } else {
   this.dispemailerr = false;
   this.isformerror = true;
  }
 },(errorResponse: HttpErrorResponse) => {
  // Extract form error messages from API  <------ HERE!!!
  this.reloadcaptcha();
  this.dispemailerr = false;
  this.isformerror = true;
  this.viewbutton = false;
  const errors = [];
  if (errorResponse.error) {
    //errors.push(errorResponse.error.message);
    this.siteToken = "";
    if (errorResponse.error.errors) {
      for (const property in errorResponse.error.errors) {
        if (errorResponse.error.errors.hasOwnProperty(property)) {
          const propertyErrors: Array<string> = errorResponse.error.errors[property];
          propertyErrors.forEach(error => errors.push(error));
          this.istrue = true;
          this.erroresponse = errors;
          this.reloadcaptcha();
        }
      }
    }
  }
  console.log(errors);
});

} else {
  this.isformerror = false;
}

}

onPhone(form: NgForm){
     var phoneNo= form.value.phoneNo;
       console.warn(phoneNo)
     const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin','*');
      this.http.post('https://staffdesk.infinitysoftware.asia/api/angular/sites/enquiry-ref',{phone: form.value.phoneNo, domain: form.value.domain},{'headers' : headers}).subscribe(response => {
      console.warn('got the post response',response);
      if(response == 'Success'){
        this.phonerr = false;
        this.phonesucc= true;

      }
      else{
        this.phonerr = true;
        this.phonesucc= false;
      }

        });
     }

}
